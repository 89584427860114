<template>
  <v-row no-gutters>
    <v-col>
      <v-app-bar elevation="0" class="transparent general-settings-header px-4">
        <v-toolbar-title class="font-w-500 text-h4 ap-dark-gray--text pa-0">
          General Settings
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          outlined
          rounded
          color="ap-grey"
          class="pa-2 bottom-shadow no-text-transform font-weight-light"
        >
          <v-icon class="ap-light-blue--text"> $mdi-help-circle </v-icon>
          <span class="ap-light-blue--text text-caption ml-1">
            Get more context on this page</span
          >
        </v-btn>
      </v-app-bar>
      <div class="ma-4">
        <v-card
          v-for="card in generalSettings"
          :key="card.id"
          tile
          class="mx-4 my-6 settings-card bottom-shadow"
          outlined
        >
          <v-row no-gutters>
            <v-col cols="10">
              <v-card-title class="primary--text font-weight-bold">
                {{ card.title }}
              </v-card-title>
              <v-card-text>
                <div
                  v-for="link in card.links"
                  :key="link.id"
                  class="pl-4 pb-1"
                >
                  <router-link
                    :to="card.path"
                    class="primary--text no-text-decoration"
                  >
                    {{ link.name }}
                  </router-link>
                </div>
              </v-card-text>
            </v-col>
            <v-spacer />
            <v-col cols="1" class="mr-4">
              <div class="btn-navigation">
                <v-btn icon :to="card.path">
                  <v-icon class="primary--text"> $mdi-chevron-right </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      generalSettings: [
        {
          id: '1',
          title: 'Setup',
          links: [
            {
              id: '1',
              name: 'Check for duplicates',
            },
            { id: '2', name: 'Indexing' },
          ],
          path: '/admin/general-settings/setup',
        },
        {
          id: '2',
          title: 'Governance Rules',
          links: [
            {
              id: '1',
              name: this.$t('cross_object_field_mapping'),
            },
            {
              id: '2',
              name: 'Grouped Fields',
            },
            {
              id: '3',
              name: 'Equivalent Term',
            },
          ],
          path: '/admin/general-settings/governance-rules',
        },
        {
          id: '3',
          title: 'Notifications',
          links: [{ id: '1', name: 'Slack' }],
          path: '/admin/general-settings/notifications',
        },
      ],
    }
  },
}
</script>

<style scoped>
.general-settings-header {
  height: 60px !important;
}

.general-settings-header > .v-toolbar__content {
  padding: 0 !important;
  height: 60px !important;
}

.btn-navigation {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.settings-card {
  border: none !important;
  border-radius: 4px !important;
}

.bottom-shadow {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2) !important;
}

.no-text-decoration {
  text-decoration: none !important;
}
</style>
