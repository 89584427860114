<template>
  <div class="rules-home-container">
    <v-card class="pb-8" min-height="100%">
      <AdminBreadcrumb />
      <v-row no-gutters>
        <v-col class="pt-2 pb-8">
          <span class="ap-dark-gray--text text-h4 px-4"> Business Rules </span>
        </v-col>
      </v-row>
      <v-divider class="mx-2" />
      <v-row no-gutters class="pa-4 pt-12">
        <v-card
          v-for="(item, idx) in allowedModules"
          :key="idx"
          :color="item.color"
          :to="item.path"
          :ripple="false"
          outlined
          width="160"
          height="200"
          class="mr-4 mb-2 module d-flex align-stretch"
        >
          <v-col class="d-flex flex-column align-center pt-14">
            <v-icon color="white" size="50">
              {{ item.icon }}
            </v-icon>
            <v-card-text
              class="px-2 py-4 text-subtitle-1 font-weight-medium ap-white--text rule-title d-flex align-center justify-center"
            >
              {{ item.title }}
              <v-icon color="white"> $mdi-chevron-right </v-icon>
            </v-card-text>
          </v-col>
        </v-card>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdminBreadcrumb from '@/components/shared/AdminBreadcrumb'

export default {
  components: {
    AdminBreadcrumb,
  },
  data() {
    return {
      modules: [
        {
          title: 'Block On Entry',
          path: '/admin/business-rules/block-on-entry',
          icon: '$mdi-block-helper',
          color: '#F2994A',
          requiredPermission: 'admin.roles.get', // TODO: Set proper role
        },
        {
          title: 'Detect & Merge',
          path: '/admin/business-rules/detect-and-merge',
          icon: '$mdi-call-merge',
          color: 'ap-light-blue',
          requiredPermission: 'admin.roles.get', // TODO: Set proper role
        },
        {
          title: 'Validation',
          path: '/admin/business-rules/validation',
          icon: '$mdi-file-check-outline',
          color: '#219653',
          requiredPermission: 'admin.roles.get', // TODO: Set proper role
        },
        {
          title: 'Mass Update',
          path: '/admin/business-rules/mass-update',
          icon: '$mdi-cloud-download-outline',
          color: 'ap-dark-blue',
          requiredPermission: 'admin.roles.get', // TODO: Set proper role
        },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', ['userHasRole']),
    allowedModules() {
      const allowedModules = this.modules.filter((el) => {
        return this.userHasRole(el.requiredPermission)
      })

      return allowedModules
    },
  },
}
</script>

<style scoped lang="scss">
.rules-home-container {
  height: calc(100vh - 24px);

  .module {
    .rule-title {
      position: absolute;
      bottom: 10px;
    }
  }
}
</style>
