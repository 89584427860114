<template>
  <div class="rule-overview-container">
    <!-- <v-card class="pb-8" min-height="100%"> -->
    <AdminBreadcrumb />
    <div class="rule-icon d-flex justify-center align-center ap-light-blue">
      <v-icon color="white" size="40"> $mdi-call-merge </v-icon>
    </div>
    <div class="pt-2 pb-8">
      <span class="ap-dark-gray--text text-h4 px-4"> Detect and Merge </span>
    </div>

    <ApLoading v-if="isLoading" text="Loading Director Rules..." />
    <RulesStatusError v-else-if="isError" @click="getRules" />

    <template v-else>
      <div class="d-flex justify-end px-4">
        <v-btn
          color="primary"
          class="text-none font-weight-light"
          :loading="isCreating"
          @click="isCreateDirectorRuleDialogVisible = true"
        >
          <span class="text-body-2">Add New Rule</span>
          <v-icon class="text-body-2 ml-5"> $mdi-plus </v-icon>
        </v-btn>
      </div>

      <DirectorRulesList :rules="directorRules" @edit="onEdit" />
    </template>

    <!-- <v-footer absolute class="white">
        <div class="text-caption primary--text">CDBS-0.1</div>
      </v-footer>
    </v-card> -->

    <DirectorRulesForm v-if="isDirectorRulesFormVisible" @close="onClose" />

    <ApDialog
      v-model="isCreateDirectorRuleDialogVisible"
      title="New Rule"
      :actions="directorRuleActions"
      @cancel="closeDirectorRuleDialog"
      @save="saveDirectorRule"
    >
      <template #content>
        <div class="px-6 pt-2">
          <v-form ref="directorRuleForm">
            <v-text-field
              v-model="directorRuleName"
              outlined
              dense
              label="Name"
              :rules="mandatoryRule"
            />

            <v-text-field
              v-model="directorRuleDescription"
              dense
              outlined
              label="Description"
              :rules="mandatoryRule"
            />
          </v-form>
        </div>
      </template>
    </ApDialog>
  </div>
</template>

<script>
import ApDialog from '@/components/common/ApDialog'
import AdminBreadcrumb from '@/components/shared/AdminBreadcrumb'
import ApLoading from '@/components/common/ApLoading'
import RulesStatusError from './components/business-rules/RulesStatusError'
import DirectorRulesList from './components/business-rules/DirectorRulesList'
import DirectorRulesForm from './components/business-rules/DirectorRulesForm'
import {
  getRules,
  createDirectorRule,
  updateDirectorRule,
  RULE_TYPES,
} from '@/api/business-rules/rules'
import { mapActions, mapState } from 'vuex'
import Vue from 'vue'

export default {
  components: {
    ApDialog,
    AdminBreadcrumb,
    ApLoading,
    RulesStatusError,
    DirectorRulesList,
    DirectorRulesForm,
  },
  data() {
    return {
      isCreateDirectorRuleDialogVisible: false,
      directorRuleName: null,
      directorRuleDescription: null,
      isLoading: false,
      isError: false,
      isCreating: false,
      isDirectorRulesFormVisible: false,
      mandatoryRule: [(v) => !!v || ''],
      ruleBeingEditedIndex: -1,
    }
  },
  computed: {
    ...mapState('auth', ['agentId']),
    ...mapState('business-rules', ['directorRules']),
    directorRuleActions() {
      return [
        {
          event: 'cancel',
          label: 'Cancel',
          text: true,
          color: 'primary',
        },
        {
          event: 'save',
          label: 'Save',
          color: 'primary',
          loading: this.isCreating,
        },
      ]
    },
  },
  created() {
    this.getRules()
  },
  methods: {
    ...mapActions('business-rules', ['getDirectorRules']),
    async getRules() {
      this.isLoading = true
      this.isError = false

      try {
        await this.getDirectorRules()
      } catch {
        this.isError = true
      } finally {
        this.isLoading = false
      }
    },
    saveDirectorRule() {
      if (this.ruleBeingEditedIndex >= 0) {
        this.updateDirectorRule()
      } else {
        this.createNewDirectorRule()
      }
    },
    async createNewDirectorRule() {
      if (this.$refs.directorRuleForm.validate()) {
        this.isCreating = true

        const multiMapRules = await getRules({
          type: RULE_TYPES.multiMap,
        })

        const presentationRules = await getRules({
          type: RULE_TYPES.presentation,
        })

        const newDirectorRule = await createDirectorRule({
          name: this.directorRuleName,
          agentId: this.agentId,
          description: this.directorRuleDescription,
          entities: 'Account',
          type: RULE_TYPES.director,
          autoMergeEnabled: false,
          status: 'inactive',
          rules: {
            [multiMapRules[0].type]: {
              id: multiMapRules[0].id,
              type: multiMapRules[0].type,
            },
            [presentationRules[0].type]: {
              id: presentationRules[0].id,
              type: presentationRules[0].type,
            },
          },
        })

        this.$router.push({
          name: 'admin-manage-business-rule',
          params: { directorRuleId: newDirectorRule.id },
        })

        this.isCreating = false
      }
    },
    onEdit(directorRuleId) {
      this.ruleBeingEditedIndex = this.directorRules.findIndex(
        (rule) => rule.id === directorRuleId
      )

      this.directorRuleName = this.directorRules[this.ruleBeingEditedIndex].name
      this.directorRuleDescription =
        this.directorRules[this.ruleBeingEditedIndex].description

      this.isCreateDirectorRuleDialogVisible = true
    },
    closeDirectorRuleDialog() {
      this.isCreateDirectorRuleDialogVisible = false
      this.directorRuleName = null
      this.directorRuleDescription = null
      this.ruleBeingEditedIndex = -1
    },
    async updateDirectorRule() {
      if (this.$refs.directorRuleForm.validate()) {
        this.isCreating = true

        const ruleUpdated = await updateDirectorRule({
          ...this.directorRules[this.ruleBeingEditedIndex],
          name: this.directorRuleName,
          description: this.directorRuleDescription,
        })

        Vue.set(this.directorRules, this.ruleBeingEditedIndex, ruleUpdated)

        this.isCreateDirectorRuleDialogVisible = false
        this.directorRuleName = null
        this.directorRuleDescription = null
        this.ruleBeingEditedIndex = -1
        this.isCreating = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.rule-overview-container {
  height: calc(100vh - 24px);

  .rule-icon {
    width: 52px;
    height: 56px;
    position: absolute;
    top: 0;
    right: 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>
