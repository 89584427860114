<template>
  <v-card outlined class="rule pa-3 d-flex" @click="openRule">
    <div class="d-flex align-center">
      <v-switch
        v-model="isActive"
        hide-details
        class="ma-0"
        color="ap-green"
        small
        dense
        :loading="isUpdating"
        @click.stop
        @change="onStatusChange"
      />
    </div>
    <div class="d-flex flex-column ml-2" style="flex-grow: 1">
      <div class="d-flex align-center">
        <span class="font-weight-black text-body-1 mr-3">
          {{ rule.name }}
        </span>
      </div>
      <div class="d-flex align-center description text-body-2">
        {{ rule.descriptionTruncated }}
      </div>
    </div>
    <div class="d-flex align-center">
      <v-btn icon outlined class="rounded mr-2" @click.stop="$emit('edit')">
        <v-icon> $mdi-pencil </v-icon>
      </v-btn>
    </div>

    <ApDialog
      v-model="isConfirmDialogVisible"
      :title="isActive ? 'Enable rule' : 'Disable rule'"
      :content="`Are you sure you want to ${
        isActive ? 'enable' : 'disable'
      } the ${rule.name} rule?`"
      :actions="confirmActions"
      @cancel="onCancel"
      @confirm="updateRuleStatus"
    />
  </v-card>
</template>

<script>
import ApDialog from '@/components/common/ApDialog'
import { updateDirectorRule } from '@/api/business-rules/rules'
export default {
  name: 'RulesListItem',
  components: {
    ApDialog,
  },
  props: {
    rule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isActive: false,
      isConfirmDialogVisible: false,
      isUpdating: false,
      confirmActions: [
        {
          event: 'cancel',
          label: 'Cancel',
          color: 'primary',
          outlined: true,
        },
        {
          event: 'confirm',
          label: 'Confirm',
          color: 'primary',
        },
      ],
    }
  },
  created() {
    this.isActive = this.rule.isStatusActive
  },
  methods: {
    onStatusChange() {
      this.isConfirmDialogVisible = true
    },
    onCancel() {
      this.isActive = !this.isActive
      this.isConfirmDialogVisible = false
    },
    async updateRuleStatus() {
      this.isConfirmDialogVisible = false
      this.isUpdating = true
      try {
        await updateDirectorRule({
          ...this.rule,
          status: this.isActive ? 'active' : 'inactive',
        })
      } finally {
        this.isUpdating = false
      }
    },
    openRule() {
      this.$router.push({
        name: 'admin-manage-business-rule',
        params: { directorRuleId: this.rule.id },
      })
    },
  },
}
</script>
