<template>
  <v-dialog :value="true" width="auto" max-width="1180" persistent>
    <v-card class="pb-4">
      <v-card-title class="pa-6">
        <v-icon
          v-ripple
          class="ap-light-grey pa-1 rounded-sm cursor-pointer close-btn"
          size="20"
          @click="onCancel"
        >
          $mdi-close
        </v-icon>
      </v-card-title>

      <v-card-text class="rules-container mx-auto">
        <v-container fluid>
          <p
            class="text-center ap-black--text font-weight-bold rule-wizard-title"
          >
            {{ $t('rule_wizard.which_objects_question') }}
          </p>

          <p class="text-center ap-black--text subtitle-1 pt-2">
            Please select one of the below options to start setting up your
            rules. Please note that this selection cannot be changed at a later
            stage.
          </p>

          <div class="d-flex justify-center">
            <v-radio-group v-model="selectedObject" row>
              <template v-for="(object, i) in entities">
                <v-radio
                  :key="i"
                  class="custom-radio border rounded"
                  :value="object"
                >
                  <template slot="label">
                    {{ object.labelPlural }}
                  </template>
                </v-radio>
              </template>
            </v-radio-group>
          </div>

          <div v-if="selectedObject">
            <v-divider />
            <v-row no-gutters class="pt-4">
              <v-col>
                <div class="d-flex">
                  <p class="text-body-1 ap-black--text">
                    {{ entityQuestion }}
                  </p>
                  <v-spacer />
                  <div class="d-flex">
                    <v-radio-group
                      v-model="selectedObjectAction"
                      class="mt-0 pt-0"
                      row
                    >
                      <v-radio
                        v-for="(action, actionIndex) in selectedObject.actions"
                        :key="actionIndex"
                        :value="action.name"
                        class="mr-4 mt-0"
                      >
                        <template slot="label">
                          <span class="ap-black--text">{{ action.name }}</span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-divider />

            <v-row no-gutters class="pt-4">
              <v-col>
                <p
                  v-if="selectedObject.conversion"
                  class="text-body-1 ap-black--text"
                >
                  {{ $t('rule_wizard.what_type_of_conversion_subquestion') }}
                </p>
              </v-col>
            </v-row>

            <v-row v-if="selectedObject.conversion" no-gutters>
              <v-col cols="4" class="my-auto">
                <div class="d-flex justify-center">
                  <div class="border rounded pa-3 my-4">
                    <h2 class="text-body-1 px-1 font-weight-bold">
                      {{ selectedObject.conversion.from }}
                    </h2>
                    <span class="text-body-1 ap-black--text px-1"
                      >If multiple Leads:</span
                    >
                    <v-radio-group
                      v-model="selectedLeadEntityAction"
                      row
                      class="ma-0"
                    >
                      <template
                        v-for="(option, optionIndex) in selectedObject
                          .conversion.options"
                      >
                        <v-radio
                          :key="optionIndex"
                          class="rounded ap-black--text"
                          :value="`${option.name}`"
                        >
                          <template slot="label">
                            <span class="text-body-1 ap-black--text">{{
                              option.label
                            }}</span>
                          </template>
                        </v-radio>
                      </template>
                    </v-radio-group>
                  </div>
                </div>
              </v-col>

              <v-col cols="4" class="my-auto">
                <v-row no-gutters>
                  <v-col cols="6" class="my-auto ml-n1">
                    <div class="main-divider">
                      <v-divider
                        class="custom-divider"
                        :class="{
                          active:
                            selectedObjectAction ||
                            selectedOtherEntities.length,
                        }"
                      />
                      <div class="circle-chain d-flex justify-space-around">
                        <v-img
                          v-if="isConvertOption || isBothOption"
                          :max-height="36"
                          :max-width="36"
                          class="circle"
                          src="@/assets/images/rules/ellipse.svg"
                        >
                          <v-icon class="chain" color="white">
                            $progressArrow
                          </v-icon>
                        </v-img>
                        <v-img
                          v-if="isAssociateOption || isBothOption"
                          :max-height="36"
                          :max-width="36"
                          class="circle"
                          src="@/assets/images/rules/ellipse.svg"
                        >
                          <v-icon class="chain" color="white"> $chain </v-icon>
                        </v-img>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="6" class="my-auto">
                    <v-img
                      :max-height="140"
                      :max-width="140"
                      :src="getUpArrowImage"
                    />
                    <v-img
                      :max-height="140"
                      :max-width="140"
                      :src="getDownArrowImage"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="selectedObject.conversion" cols="4">
                <div
                  v-for="(entityType, key) in selectedObject.conversion.to"
                  :key="key"
                  class="border rounded pa-3 my-4"
                  :class="{
                    active: selectedOtherEntities.includes(entityType),
                  }"
                >
                  <v-checkbox
                    v-model="selectedOtherEntities"
                    :name="entityType"
                    :value="entityType"
                    multiple
                    class="mr-4 mt-0"
                  >
                    <template slot="label">
                      <span
                        class="ap-black--text text-body-1 px-1 font-weight-bold"
                        >{{ entityType }}</span
                      >
                    </template>
                  </v-checkbox>

                  <div :class="{ disabled: isEntityDisabled(entityType) }">
                    <span class="text-body-1 ap-black--text px-1">
                      If multiple {{ entityType + 's' }}:
                    </span>

                    <v-radio-group
                      v-model="selectedOtherEntityAction[key]"
                      :disabled="isEntityDisabled(entityType)"
                      row
                      class="ma-0"
                    >
                      <v-radio
                        v-for="(option, optionIndex) in selectedObject
                          .conversion.options"
                        :key="optionIndex"
                        :value="option.name"
                        class="rounded ap-black--text"
                      >
                        <template slot="label">
                          <span class="text-body-1 ap-black--text">
                            {{ option.label }}
                          </span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>

      <v-divider />

      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="primary"
          large
          :disabled="isSubmitButtonDisabled"
          @click="saveConfiguration()"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data() {
    return {
      selectedObject: null,
      selectedObjectAction: null,
      selectedLeadEntityAction: null,
      selectedOtherEntities: [],
      selectedOtherEntityAction: [],
    }
  },
  computed: {
    ...mapState('business-rules', ['entities']),
    ...mapState('auth', ['user']),
    isAssociateOption() {
      return this.selectedObjectAction === 'Associate'
    },
    isConvertOption() {
      return this.selectedObjectAction === 'Convert'
    },
    isBothOption() {
      return this.selectedObjectAction === 'Both'
    },
    isAccountConversionActive() {
      return this.selectedOtherEntities.includes('Account')
    },
    isContactConversionActive() {
      return this.selectedOtherEntities.includes('Contact')
    },
    getUpArrowImage() {
      const image = this.isAccountConversionActive
        ? 'coloredarrowup.svg'
        : 'arrowup.svg'

      return require(`@/assets/images/rules/${image}`)
    },
    getDownArrowImage() {
      const image = this.isContactConversionActive
        ? 'coloredarrowdown.svg'
        : 'arrowdown.svg'

      return require(`@/assets/images/rules/${image}`)
    },
    isSubmitButtonDisabled() {
      if (!this.selectedObject || !this.selectedObjectAction) {
        return true
      }

      if (this.selectedObject.type === 'process') {
        return (
          !this.selectedOtherEntities.length ||
          !this.selectedOtherEntityAction.length
        )
      }

      return false
    },
    entityQuestion() {
      return this.selectedObject?.type !== 'process'
        ? this.$t('rule_wizard.what_action_question')
        : this.$t('rule_wizard.what_type_of_conversion_question')
    },
  },
  created() {
    this.getEntities()
  },
  methods: {
    ...mapActions('business-rules', ['getEntities']),
    async saveConfiguration() {
      // let entityFrom = this.selectedObject
      // if (this.selectedObject.type === 'process') {
      //   entityFrom = this.entities.find((el) => el.name === this.selectedObject.conversion.from)
      // }
      // const entitiesTo = this.selectedOtherEntities.map((entityName) => {
      //   const entity = this.entities.find((el) => el.name === entityName)
      //   return {
      //     name: entity.name,
      //     label_plural: entity.labelPlural,
      //     action: this.selectedLeadEntityAction,
      //   }
      // })
      // const entities = [
      //   {
      //     name: entityFrom.name,
      //     label_plural: entityFrom.labelPlural,
      //     action: this.selectedObjectAction,
      //   },
      //   ...entitiesTo,
      // ]
      // const governanceRule = entities.map((el) => el.name).join('_')
      // const payload = {
      //   name: this?.user?.fullName || 'Lorem Ipsum',
      //   description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      //   type: '',
      //   rule: {
      //     entities,
      //     governanceRule,
      //     class: 'OrClause',
      //     config: [
      //       {
      //         class: 'AndClause',
      //         config: {
      //           conditions: [],
      //         },
      //       },
      //     ],
      //   },
      //   entities: governanceRule,
      // }
      // const ruleCreated = await this.createRule(payload)
      // this.$router.push({
      //   name: 'manage-business-rule',
      //   params: {
      //     ruleId: ruleCreated.id,
      //   },
      // })
    },
    isEntityDisabled(value) {
      return !this.selectedOtherEntities.includes(value)
    },
    onCancel() {
      this.$emit('close', null)
    },
  },
}
</script>

<style scoped lang="scss">
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.custom-radio {
  padding: 8px;
  margin: 8px 0;
  width: 180px;
  font-weight: bold;
}
.rules-container {
  max-width: 880px;

  .rule-wizard-title {
    font-size: 1.75em;
  }

  .disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
.border {
  border: 1px solid var(--v-ap-grey-base);

  &.active {
    border: 1px solid var(--v-ap-dark-blue-base);
  }
}

.main-divider {
  position: absolute;
  width: 136px;

  .custom-divider {
    position: absolute;
    top: -5px;
    left: 3px;
    border-width: 2px;
    width: inherit;
    color: var(--v-ap-grey-base);

    &.active {
      border-color: var(--v-ap-yellow-10-base) !important;
    }
  }
  .circle-chain {
    &.progress {
      position: relative;
      left: 38px;
    }
    .circle {
      top: -22px;
    }
    .chain {
      position: absolute;
      top: 5px;
      left: 5px;
    }
  }
  .circle-progress {
    position: absolute;
    top: -20px;

    .circle {
      top: -2px;
    }
    .chain_progress {
      position: absolute;
      top: 13px;
    }
  }
}
</style>
