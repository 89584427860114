<template>
  <div class="px-4 py-2">
    <DirectorRulesListItem
      v-for="rule in rules"
      :key="rule.id"
      :rule="rule"
      class="mb-1 director-rule-list-item"
      @edit="$emit('edit', rule.id)"
    />
  </div>
</template>

<script>
import DirectorRulesListItem from './DirectorRulesListItem'

export default {
  name: 'RulesList',
  components: {
    DirectorRulesListItem,
  },
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style scoped>
.director-rule-list-item:hover {
  background-color: var(--v-ap-dark-blue-10-base);
}
</style>
